$header-footer-height: 40px;
$font-family: "Montserrat", sans-serif;

$primary-color-mainHeader: /*  */#00a76d; // Dark shade for primary elements
$primary-color-subPageHeader: rgba(244, 247, 246, 0.7);
$primary-color-content: #00a76d;

$table-heads-color: /* #2c3e50 */ #00a76d;

$form-padding: 10px;
$section-padding-bottom: 5px;
$section-padding-top: 5px;
$border-color: #ddd;         // Light gray
$primary-color: #636B59;     // Soft blue
$secondary-color: #e74c3c;   // Dark red
$button-background: #636B59; // Soft blue
$button-color: #fff;         // White
$button-padding: 10px 20px;
$button-border-radius: 5px;
$button-transition: 0.2s ease-in;
$background-color: #f9f9f9;  // Light gray
$resizer-color: #f1f1f1;     // Light gray
$input-border-color: #ccc;   // Light gray
$input-border-radius: 5px;
$input-padding: 10px;
$additionalSectionColor: darkgreen;  // Dark green
