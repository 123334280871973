@import './globals/@media';

@import './global_styles';
@import './variables';
@import '../index.styles';
@import '../Additional_Components/ActionLog.styles';

@import './globals/module-navbar';
@import './globals/search-bar';
@import './globals/inputs';
@import './globals/buttons';
@import './globals/helpers';
@import './globals/colors';
@import './globals/form';
@import './globals/table';

@import '../Pages/Main/main.styles';
@import '../Pages/Main/Components/UploadExcelPopup.styles';
@import '../Pages/Main/Components/descriptionBox.styles';
@import '../Pages/Main/Components/Overview.styles';
@import '../Pages/Main/Components/recursive.styles';
@import '../Pages/monitoring/monitoring.styles';
@import '../Pages/geotechnics/geotechnical.repository.styles';
@import '../Pages/access.control.dashboard/access.control.dashboard.styles';
@import '../Pages/Login/Login.styles';
@import '../Pages/virtualTour/virtual.tours.styles';
@import '../Pages/Profile/Profile.styles';
@import '../Pages/UsersTable/users.table.styles';
@import '../Pages/qgis.repository/qgis.repository.styles';
@import '../Pages/ifc.repository/IFC.viewer.styles';
@import '../Pages/prototype_vozni_red/vozni_red.styles';

.customScroll {
    &::-webkit-scrollbar {
        width: 8px;
        background-color: rgba(0, 0, 0, 0.05);
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 4px;

       
        transition: background-color 0.2s ease;

        &:hover {
            background-color: rgba(0, 0, 0, 0.5);
        }
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }
}

.add-new-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 6px;
    margin: 5px 0;
    border: none;
    border-radius: 5px;
    
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    flex-direction: column;
    
    
    &.project {
        background-color: #007BFF; 
        &:hover {
        background-color: #0056b3;
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
        }
    
        &:active {
        background-color: #004085;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
    
        &:focus {
        outline: none;
        }
    }
    &.workplace {
        background-color: #008248;
        &:hover {
        background-color: #026435;
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
        }
    
        &:active {
        background-color: #014021;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
    
        &:focus {
        outline: none;
        }
    }
    &.sensor {
        background-color: #FFA500; 
        
        &:hover {
        background-color: #FF8C00;
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
        }
        
        &:active {
        background-color: #FF6F00;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
        
        &:focus {
        outline: none;
        }
    }
    }
    
    .TreeItem {
        border-bottom: 1px solid #ccc;
        padding: 2px;
        margin-bottom: 2px;
        font-weight: normal;
        
    
    &-label {
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #333;
        transition: color 0.2s, background-color 0.2s; 
        background-color: #f5f5f5;
        padding: 2px 5px;
        font-size: 14px;
        border-radius: 5px;
        flex-direction: row;
        justify-content: space-between;
        
    
        .edit {
        padding-left: 3px;
        transition: color 0.2s, background-color 0.2s;
        &:hover {
            color: #4863da;
            background-color: #ffc4c4;
        }
        }
        svg {
        width: 14px;
        height: 14px;
        margin-right: 6px;
        transition: transform 0.2s ease-in-out;
        }
    
        &:hover {
        color: #1e9900;
        background-color: #e0e0e0; 
        }
    }
    
    &-children {
        margin-left: 10px;
    }
    }

    #map {

        width: 100%;
        height: 100%;
}

