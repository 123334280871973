$bblack: #494949;
$bwhite: #fff;
$bgreen: #20bf6b;
$color: #333;
$bred: #ed3330;
$red: #BE0000;
$green: #009187;
$veryLightGrey: #eee;
$white: #FBFFFE;

input {
  border-width: 1px;
}

.LOKACIJA-admin {
  grid-area: 1/1/2/5;
  position: relative;
  top: 10%;
  text-decoration: underline;
}

.LOKACIJA-email,
.LOKACIJA-password {
  grid-area: 2/1/3/5;
  position: relative;
  font-weight: 520;
}

.LOKACIJA-login {
  grid-area: 4/1/5/3;
  position: relative;
  top: 3px;
}

.LOKACIJA-puk {
  grid-area: 4/2/5/3;
  position: relative;
  left: 5px;
}

.LOKACIJA-showpsw,
.LOKACIJA-showpswlg {
  grid-area: 4/3/5/5;
  position: relative;
}

.LOKACIJA-resetpsw {
  grid-area: 5/1/6/6;
  position: relative;
  bottom: 10%;
}

.login {
  font-size: 20px;
}

.bold {
  font-weight: 700;
}

.tal {
  text-align: left;
}

.pr {
  position: relative;
}

.input {
  margin: 4px 0px 4px 10px;
  height: 26px;/* 
  width: 225px; */

  &.input1 {
    width: 160px;
  }

  &.input2 {
    width: 120px;
  }

  &.input3 {
    width: 130px;
  }

  &.input-login {
    width: 200px;
  }

  &.input-narrow {
    width: 100px;
  }

  &.input-very-narrow {
    width: 60px;
  }

  &.input-table {
    width: 100%;
    margin: 0px;
    height: 26px;
  }

  &.input-hm {
    width: 35px;
    margin-top: 2px;
    text-align: center;
  }

  &.input-hm-narrow {
    width: 28px;
    margin-top: 2px;
    text-align: center;
  }

  &.input-advance {
    margin-top: 2px;
    width: 85px;
  }

  &.input-advance0 {
    margin-top: 2px;
    width: 130px;

    &.op0 {
      opacity: 0;
    }
  }

  &.input-file {
    z-index: 9999;
    margin: auto;
    inset: 0;
    opacity: 0;
    background: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
    cursor: pointer;
  }

  &.input-expense-name {
    margin-top: 2px;
    width: 135px;
  }

  &.input-expense-value {
    margin-top: 2px;
    width: 75px;
  }

  &.input-checkbox {
    margin-top: 2px;
    font-size: 12px;
    width: 15px;
  }

  &.input-pass {
    width: 150px;
  }

  &.pli {
    padding-left: 6px;
  }
}

.pa {
  position: absolute;
}

.version {
  right: 10px;
  font-size: 11px;
  font-weight: 400;
}

.mt5 {
  margin-top: 2px;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.tar {
  text-align: right;
}

.beforeinput {
  width: 61px;
  line-height: 32px;
}

.fl {
  float: left;
}

.dbl {
  display: block;
}

.button {
  color: $bblack !important;
  text-decoration: none;
  background: $bwhite;
  padding: 0px 10px;
  border: 2px solid $bblack !important;
  display: inline-block;
  transition: all 0.4s ease 0s;

  &.button-wide {
    padding: 0px 9px;
  }
}

.button-thin {
  padding: 0px 5px;
  line-height: 22px;
  border: 1px solid $bblack !important;
}

.button-green {
  color: $bgreen !important;
  border: 2px solid $bgreen !important;

  &.button-thin {
    border: 1px solid $bgreen !important;
  }

  &:hover,
  &.active {
    color: $bwhite !important;
    background: $bgreen;
    border-color: $bgreen !important;
    transition: all 0.4s ease 0s;
  }

  &.button-nohover {
    color: $bgreen !important;
    background: $bwhite;
    cursor: default !important;
  }

  &:disabled,
  &.inactive {
    color: $color !important;
    background: $bwhite !important;
    border: 1px solid $bblack !important;

    &:hover {
      color: $color !important;
      background: $bwhite !important;
      border: 1px solid $bblack !important;
    }
  }
}

.button-red {
  color: $bred !important;
  border: 2px solid $bred !important;

  &.button-thin {
    border: 1px solid $bred !important;
  }

  &:hover,
  &.active {
    color: $bwhite !important;
    background: $bred;
    border-color: $bred !important;
    transition: all 0.4s ease 0s;
  }

  &.button-nohover {
    color: $bred !important;
    background: $bwhite;
    cursor: default !important;
  }

  &:disabled,
  &.inactive {
    color: $color !important;
    background: $bwhite !important;
    border: 1px solid $bblack !important;

    &:hover {
      color: $color !important;
      background: $bwhite !important;
      border: 1px solid $bblack !important;
    }
  }
}

.mr {
  margin-right: 10px;
}

.red {
  color: $red;
}

.password-problem {
  font-size: 14px;
  line-height: 1.1em;
}

.green {
  color: $green;
}

.fr {
  float: right;
}

.ml5 {
  margin-left: 5px;
}

.checkbox {
  margin-top: 8px;
}

.reset-password {
  font-size: 14px;
}

.checkX {
  font-size: 20px;
}

.loginForm {
  form {
    display: grid;
    min-width: 280px;
    min-height: 180px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(5, 1fr);
    position: relative;
  }

  .LOKACIJA-email {
    grid-area: 2/1/3/5;
    position: relative;
    font-weight: 520;
  }

  .LOKACIJA-password {
    grid-area: 3/1/4/5;
    position: relative;
    font-weight: 520;
  }

  .LOKACIJA-login {
    grid-area: 4/1/5/3;
    position: relative;
    top: 3px;
  }

  .LOKACIJA-admin {
    grid-area: 1/1/2/5;
    position: relative;
    top: 10%;
    text-decoration: underline;
  }

  .LOKACIJA-puk {
    grid-area: 4/2/5/3;
    position: relative;
    left: 5px;
  }

  .LOKACIJA-showpsw {
    grid-area: 4/3/5/5;
    position: relative;
  }

  .LOKACIJA-showpswlg {
    grid-area: 4/3/5/6;
    position: relative;
  }

  .LOKACIJA-resetpsw {
    grid-area: 5/1/6/6;
    position: relative;
    bottom: 10%;
  }
}

.app {
  background: $veryLightGrey;
  height: 100%;
  text-align: center;

  > svg.fs {
    height: 1000px;
    inset: 0 40px 100px 0;
    margin: auto;
    opacity: 0.5;
  }

  > svg.mb {
    width: 80%;
    inset: 0 0 440px 0px;
    margin: auto;
    opacity: 0.5;
  }

  > span.fs {
    width: 1000px;
    height: 100px;
    display: block;
    font-size: 60px;
    text-align: center;
    inset: 0 0 600px 0;
    margin: auto;
    opacity: 1;
    font-weight: 600;
  }

  > span.mb {
    width: 100%;
    height: 50px;
    display: block;
    font-size: 30px;
    text-align: center;
    inset: 0 0 250px 0;
    margin: auto;
    opacity: 1;
    font-weight: 600;
  }
}

@media only screen and (max-width: 1023px) {
  .app {
    > svg.fs,
    > span.fs {
      display: none;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .app {
    > svg.mb,
    > span.mb {
      display: none;
    }
  }
}

.App {
  border-radius: 10px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 10px;
  margin: auto;
  padding: 8px 20px;
  background: $white;
  width: 320px;
  height: 198px;
  border: 1px solid $bblack;
  position: fixed;
  text-align: center;
}

.reset-password-link {
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
  color: $color;

  &:hover {
    color: $color;
  }
}

.message-error {
  color: $red;
  font-weight: 600;
}

.version {
  font-size: 12px;
  font-weight: 400;
}

.gray {
  color: $color;
  font-weight: 400;
}

.gray-700 {
  color: $color;
  font-weight: 700;
}

.hide {
  display: none;
}

.bold {
  font-weight: 700;
}
