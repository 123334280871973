
.virtualTourContainer {
    display: flex;
    flex-direction: row;
    max-width: 100vw;
    max-height: 100vh;
    height: 100vh;
    margin: 0;
    padding: 0;

    .virtual-tour {
        flex-grow: 1;
        max-height: 100%;
        overflow: hidden;
        
        iframe {
            width: 100%;
            max-width: 100%;
            max-height: 100%;
            height: 100%;
            border: none;
        }
    }
}