@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap');

.dashboard-main {
    background: linear-gradient(to bottom, orange, transparent);
    background-image: url('https://images.squarespace-cdn.com/content/v1/5dbac81daa74663c10257737/5e8b0006-69f9-46fc-989b-d0ca8635e9df/Artboard+1+copy+5.png?format=2500w');
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    max-width: 100vw !important;
    overflow-x: hidden;/* 
    overflow-y: hidden; */
    height: 100vh;
}

.dashboard-container {
    display: flex;
    flex-direction: column;
    color: orange;
    overflow: auto;
    height: 100%;
    max-width: 100vw !important;
    font-family: 'Roboto Mono', monospace;
}

.project-card {
    background-color: rgba(51, 51, 51, 0.9);
    border-bottom: 1px solid #fff;
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
    padding: 10px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 10px; 
    justify-content: space-between;
    height: 100%;
}

.member-details {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    font-size: small;
}

.member-details strong {
    color: #fff;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
}

.banner {
    width: 100%;
    white-space: nowrap;
    animation: slideIn 30s linear infinite;
    font-family: 'Arial', sans-serif;
    font-size: 24px;
    color: rgb(255, 255, 255);
    text-shadow: 1px 1px 2px black;
    font-weight: bold;
}

.banner-container {
    background-color: rgba(51, 51, 51, 0.9);
    border-bottom: 1px solid white;
}

.full-width {
    width: 100%;
}

.no-resize {
    resize: none;
}

.rotated-span {
    display: inline-block;
    transform: rotate(-90deg);
    margin-right: 20px;
}

.banner-font {
    font-size: clamp(2rem, 2.5vw, 3rem);
}

.flex-grow {
    flex: 1;
}

.white-border {
    border-bottom: 1px solid white;
}

.flex-end {
    justify-content: flex-end;
}

.column-gap {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.row-space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.zero-margin {
    margin: 0;
}

.bold {
    font-weight: bold;
}

.white-text {
    color: white;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.text-end {
    text-align: end;
}

.no-wrap {
    text-wrap: nowrap;
}

.small-progress {
    height: 30%;
    font-size: clamp(1rem, 2vw, 1.5rem);
}

.row-max-width {
    display: flex;
    flex-direction: row;
    max-width: 100vw;
}

.column {
    display: flex;
    flex-direction: column;
}

.flex-1 {
    flex: 1;
}

.large-font {
    font-size: clamp(1rem, 2vw, 4rem);
}

.flex-3 {
    flex: 3;
}

.inline {
    display: inline;
}

.margin-x {
    margin-right: 10px;
    margin-left: 10px;
}

.half-width {
    flex: 2;
}

.tall-progress {
    height: 90% !important;
}

.row-gap {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
}

.space-between {
    justify-content: space-between;
}

.max-height {
    max-height: 100%;
}

.overflow-auto {
    overflow: auto;
}

.border-top {
    border-top: 1px solid white;
}

@keyframes slideIn {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-200%);
    }
}

.member-details .banner-section {
    overflow: hidden;
    margin: 0 10px;
    width: 100%;
}

.member-details .banner-section .moving-section {
    animation: slideIn 30s linear infinite;
    color: #FFD700;
    font-weight: bold; 
}

.member-details ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.departure-form {
    &-container {
        height: 100%;
    }
    &-title {
        color:white;
    }

    &-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &-content {
        @extend .project-card;
        color: orange;
        &-head {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        &-body {
            &-status-container {
                min-width: 30%;
                align-self: center;
                display:flex;
                flex-direction: row;
                gap: 10px;
                font-size: clamp(1rem, 2vw, 4rem);
            }

            &-users {
                cursor: pointer;
                text-align: end;
                &:hover {
                    background-color: rgba(255, 255, 255, 0.1);;
                }
            }
        }
    }
}
