.ActionLogWrapper {
  box-sizing: border-box;
  position: absolute;
  top: 40px;
  right: 5px;
  z-index: 100;
  min-width: 250px;
  width: max-content;
  border-radius: 8px;
  min-height: 40px;
  border: 1px solid #d3d3d3;
  background-color: #f8f8f8;
  box-shadow: 0 4px 8px rgba(0,0,0,0.05);
  max-height: calc(98vh -  $header-footer-height); // Adjust based on header/footer height
  resize: vertical;
  font-family: 'Arial', sans-serif;


  .head {
    display: flex;
    box-sizing: border-box;
    width:100%;
    justify-content: space-between;
    padding: 10px;
    background-color: #e8e8e8;
    border-bottom: 1px solid #d3d3d3;

    .title {
      font-size: 18px;
      font-weight: 600;
      color: #333;
      display: flex;
      align-items: center;
    }

    .CopyToClip {
      font-size: 14px;
      color: #555;
      background-color: transparent;
      border: none;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #1a73e8;
      }
    }
  }

  ul {
    padding: 10px;
    font-size: 14px;
    overflow-y: auto;
    margin: 0;
    max-height: calc(98vh -  $header-footer-height - 82px);
    
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a0a0a0;
    border-radius: 10px;
  }

    li {
      border-bottom: 1px solid #eee;
      display: flex;
      background-color: #fff;
      justify-content: space-between;
      width: 100%;
      padding: 8px 0;
      margin: 5px 0px;
      transition: background-color 0.3s;
      cursor: help;

      &:hover {
        background-color: #f0f0f0;
      }

      .icon {
        text-align: center;
        padding-left: 3px;
      }

      .message {
        
        padding-left: 5px;
        margin-right: 10px;
      }

      .time {
        align-self: center;
        padding-right: 7px;
        color: #666;
      }
    }
  }
}
