input[type='text'],
input[type='number'],
input[type='date']{
    width: 100%;
    border-radius: 5px;
    border: 1px solid #b1b1b1;
    color: #333;
    padding: 5px 0px;

    &:focus {
        outline: none;
        border-color: #00A24D;
        box-shadow: 0 0 5px rgba(0, 162, 77, 0.5);
    }
    &::placeholder {
        color: #9a9a9a;
    }
}

select {
    padding: 3px 30px 3px 10px;
    width:100%;
    border-radius: 6px;
    border: 1px solid #d1d3d4;
    background-color: white;
    font-size: 1rem;
    color: #555;
    cursor: pointer;
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg fill="%23555" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>'); // Custom dropdown arrow
    background-repeat: no-repeat;
    background-position: right 10px center;

    &:focus {
        outline: none;
        border-color: #00A24D;
        box-shadow: 0 0 5px rgba(0, 162, 77, 0.5);
    }
  }