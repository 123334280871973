// Variables
$primaryColor: #f5f5dc;
$secondaryColor: #008000;
$fontSizeSmall: 11px;
$fontSizeMedium: 14px;
$fontSizeLarge: 16px;
$fontSizeExtraLarge: 18px;


.recursiveWrapper {
  user-select: none;
  box-sizing: border-box;
  width: 100%;

  .regularNode {
    display: flex;
    background-color: #fafafa;
    border: 1px solid rgba(220, 220, 220, 0.8);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
    transition: all 0.2s ease-in-out;
    color: #444;
    box-sizing: border-box;
  }
  
  .childNode {
    display: flex;
    box-sizing: border-box;
    background-color: #f2f2f2;

    border: 1px solid rgba(200, 200, 200, 0.8);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.02);
    border-radius: 6px;
    transition: all 0.2s ease-in-out;
    color: black;
  }
  
  .specialNode {
    display: flex;
    border: 1px solid rgba(0, 50, 0, 0.2);
    background-color: #eef0f1;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    padding: 0px 0px;
    padding-left: 15px;
    color: #333;
    box-sizing: border-box;
  }


    .buttonWithWidth {
      max-width: 55px;
      min-width: 55px;
    }

    .divWithWidth {
      max-width: 70px;
      min-width: 70px;
    }

    .greenButton {
      color: green;
      cursor: pointer;
    }

  &:hover {
    background-color: white;
    transition: background-color 0.3s ease-in-out;
    border-radius: 6px;
  }
  .rec-title {
    font-size: $fontSizeSmall;
    font-weight: 500;
  }

  .quickInfoRight {
    display: flex;
    flex-direction: row;
    gap: 5px;/* 
    grid-template-columns: 60px 20px min-content min-content 30px 50px 60px 30px 70px 30px 140px 140px 140px 140px; */
    align-items: center;
    text-align: center;

    &.move-items {
      grid-template-columns: 100% 100% 100%;
    }

    .fileExtension {
      color: darkred;
      font-size: $fontSizeSmall;
    }
    .itemSection {
      font-size: $fontSizeSmall;
    }
    .typeOfItem{
      font-size: $fontSizeSmall;
      color:brown;
    }
    .scale {
      font-size: $fontSizeSmall;
      color: blue;
    }
    .link {
      font-size: $fontSizeSmall;
      cursor:pointer;
      color: blue;
      white-space: nowrap;
      padding-right: 10px;
    }
    .descriptionIcon {
      font-size: $fontSizeMedium;
      color:red;
      cursor: pointer;
      margin-right: 5px;
      .name {
        font-size: $fontSizeMedium;
      }
    }
    .description {
      overflow-wrap: break-word;
      min-height: 50px;
      width: 250px;
      resize: vertical;
    }
    .dateCreated{
      font-size: $fontSizeSmall;

      .dateCreatedName {
        font-size: $fontSizeSmall;
        color:blue;
      }
      .deadline {
        font-size: $fontSizeSmall;
        color: red;
      }
    }

    .moveItemsContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0;
      margin: 0;
      font-size: 14px;
    
      .flexRow {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px; 

            .parentIdContainer,
            .priorityNumberContainer,
            .copyToClipButton {
              // Shared styles for buttons and input containers
              display: flex;
              align-items: center;
              padding: 1px 10px;
              margin: 1px;
              border-radius: 4px;
              background-color: white;
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
              font-size: 14px;
              color: #333;
              margin-right: 10px;
        
              &:last-child {
                margin-right: 0;
              }
        
              span.icon {
                margin-right: 5px;
              }
        
              input, button {
                margin-left: 5px;
                border: 1px solid #ccc;
                border-radius: 4px;
                padding: 2px 10px;
                outline: none;
        
                &:focus {
                  border-color: #007bff;
                }
              }
        
              button {
                background-color: #007bff;
                color: white;
                cursor: pointer;
        
                &:hover {
                  background-color: darken(#007bff, 10%);
                }
              }
            }
        
            .parentIdContainer, .priorityNumberContainer {
              flex-direction: row;
              justify-content: flex-start;
              
              input[type='text'], input[type='number'] {
                max-width: 100px;
              }
        }
        
        .copyToClipButton {
          margin: 0px 50px;
          display: flex;
          align-items: center;
          cursor: pointer;
          max-width: 100px;
        
          .text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 80%;
          }
        }
      }
    }
  }

  .context {
    background-color: #ffffff;
    position: absolute;
    list-style-type: none;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 5px 0; 

    li {
        cursor: pointer;
        padding: 10px 20px;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #f5f5f5;
        }
    }
}

  .openDivider{
    @extend .closedDivider;
    color: red;
    border-color: red; 
  }


  .closedDivider {
    cursor: pointer;
    padding: 2px 10px;
    color: #333;
    width: 50px;
    transition: color 0.2s, background-color 0.2s;
    display: flex;
    align-items: center;
    border: 1px solid #ccc; 
    border-radius: 4px; 
    font-size: $fontSizeMedium;
  
    &:hover {
      color: darkred;
      background-color: #f9f9f9;
      border-color: darkred; 
    }
  }
  
  
  .subitemsNumber {
      font-size: $fontSizeSmall;
      padding-left: 3px;
      vertical-align: top;
    }

  .itemName {
    display: block;
    justify-content: space-between;
    padding-left: 10px;
    margin: 2px 0px;
    border-radius: 10px;
    font-size: $fontSizeMedium;
    background-color: transparent;
    width: 260px;
    cursor: help;
    white-space: normal;
    word-wrap: normal;
}


  .openingMovement {
    box-sizing: border-box;
    display: block;
    padding: 0 0 0 10px;
    border-left: 1px solid rgb(105, 105, 105);
  }
}
