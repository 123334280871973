.Monitoring-wrapper {
  display: flex;
  flex-direction: row; 
  flex-grow: 1; 
  overflow: hidden; 
  position: relative;
  box-sizing: border-box;
  height: 100vh;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .menu {
    background-color: #f7f8fa;
    flex: 0 0 auto;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  .graph {
    background-color: rgb(247, 248, 250);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 1 auto; /* Take remaining space */
    
    .graph-item {
      flex: 0 0 50%; /* This will work for two graphs */
      min-width: 250px;
    }
    
    .graph-item.full-width {
      flex: 0 0 100%; /* This will be used for a single graph */
      min-width: 500px;
    }

    @media (max-width: 768px) {
      .graph-item {
        flex: 0 0 100%; /* This will be used for a single graph */
      }
    }
  }
  .Sensor {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 10px;
    padding: 6px 0;
    color: #666;
    transition: color 0.2s, background-color 0.2s;
    .Sensor-index {
      min-width: 20px;
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      color: #333;
    }
  
    .Sensor-info {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  
    .Sensor-label {
      font-size: 14px;
      font-weight: bold;
      padding-left: 10px;
    }
  
    .Sensor-coordinates {
      font-size: 12px;
      color: #999;
    }
  }


.Sensor {
    display: flex;
    align-items: center;
    
    justify-content: space-between;
    margin-left: 10px;
    padding: 6px 0;
    color: #666;
    transition: color 0.2s, background-color 0.2s;
  
    &:hover {
      color: #ff9900;
      background-color: #f5f5f5;
      cursor: pointer;
    }
  
    &:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }
  }

  .Sensor-label {
    font-size: 14px;
    font-weight: bold;
    padding-left: 10px;
  }

  .Sensor-coordinates {
    font-size: 12px;
    color: #999;
  }
  .charts {
    display: flex;
    flex-direction: column;
    background-color: #f8f9fa;
    padding: 20px;
    border: 1px solid #dee2e6;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    width: 300px;
    margin: 0 auto; // Center align if needed
    overflow: hidden;
    height: 100%;

    @media (max-width: 768px) {
      display: none;
    }
    
    .legendName {
      font-weight: 600;
      font-size: 18px; // Slightly smaller for a more elegant look
      text-align: center;
      padding-bottom: 10px;
      border-bottom: 1px solid #dee2e6;
      margin-bottom: 15px;
      color: #495057;
    }
  
    input[type='text'] {
      max-width: 100%;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 4px;
      border: 1px solid #ced4da;
      font-size: 16px;
      color: #495057;
  
      &:focus {
        border-color: #80bdff;
        outline: none;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }
    }
  
    button {
      width: 100%;
      padding: 10px;
      background-color: #007bff;
      color: #ffffff;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      cursor: pointer;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  
    .chart-legend {
      max-height: 90vh;
      overflow-y: auto;
      padding: 10px;
  
      ul {
        padding: 0;
        list-style-type: none;
        margin: 0;
      }
  
      .legend-item {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
  
        .color-indicator {
          display: flex;
          align-items: center;
          font-size: 14px; // Uniform text size
          color: #495057; // Uniform text color
  
          .color-box {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin-right: 5px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
          }
        }
  
        input[type='checkbox'] {
          margin-right: 8px;
        }
      }
    }
  }
}

.insertNodeFormContainer {
  background-color: rgb(0, 0, 0,0.4);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  display: flex;       
  justify-content: center; 
  align-items: center;     
  
  .insertNodeForm {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2fr;
    padding: 20px;
    width: 80%;    /* Adjust the width as needed */
    /* height: 200px; */   /* Adjust the height as needed */
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    border: 2px solid gray;
    border-radius: 10px;

    input[type = 'number'] {
      width: 60px;
    }
    .gridContainer {
      display: grid;
      grid-template-columns: 1fr;
      grid-column: span 2;
      
      & > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
        
        & > div {
          display: flex;
          justify-content: space-between;
          width: 100%;
          
          &:not(:last-child) {
            margin-bottom: 5px;
            color: white;
          }
        }
      }
    }

    textarea {
      resize: none;
    }
    button {
      width: 100%;
      cursor: pointer;
    }
  }
  
  
}

