@import '../../styles/variables';

$primary-color: hsl(146, 50%, 36%);
$secondary-color: #3CB371;
/* $font-family: 'Arial', sans-serif; */
$header-footer-height: 40px;
$border-width: 5px;
$header-background: #d6d6d6;
$accent-color: #606060;
$button-hover-color: #cccccc;

@keyframes expandDown {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    min-height: 55px;
    opacity: 1;
  }
}

.main-component-wrapper {
  display: flex;
  flex-direction: column; 
  flex-grow: 1; 
  overflow: hidden; 
  background-color: rgb(247, 248, 250, 0.8);
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  position: relative; 

    
    .tag-filter-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 10px;
      background-color: #f8f9fa;
      resize: vertical;
    }

    .resizable-container {
      resize: vertical;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      max-height: 300px; 
      animation: expandDown 0.8s ease forwards;
      border:none;
    }
    &-main-content {
      
      display: flex;
      flex-direction: row;
      box-sizing: border-box;
      position: relative;
      overflow-y: auto;
      height: 100vh;
      max-width: 100vw;
      overflow-x: hidden;

      &-recursive-containerHelper {
        @extend .customScroll;
        flex-grow: 1;
        overflow-y: auto;
        box-sizing: border-box;
        max-width: 90vw;/* 
        padding: 0px 20px 20px 20px; */


        .addNewButton {
          margin: 5px 0px;
          width: 100%;
          text-align: left;
          color: #ccc; 
          background-color: transparent; 
          border: none;
          cursor: pointer;
          border-radius: 4px; 
          transition: background-color 0.3s ease, box-shadow 0.5s ease, color 0.3s ease; 
      
        
          &:hover {
            background-color: rgba(0, 150, 0, 0.4);
            color: #fff;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          }
        }
      }

      .descriptionBoxContainer {
        @extend .customScroll;
        background-color: #ffffff;
        margin-left: 5px;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08), 0 2px 2px rgba(0, 0, 0, 0.02);
        overflow-y: auto;/* 
        overflow-x: hidden; */
        box-sizing: border-box;
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        min-width: min-content;
        max-width: 260px;
      }
    }
  }

  
  .text-area-background-shade {
    min-width:100%;
    min-height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 500;
    top: 0;
    left: 0;
  } 

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black background
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; // Ensure it's above other content
}