.ProfileWrapper {
  background-color: #f8f8f8;
  padding: 20px 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); 
  width: 320px;
  color: #333;

  .head {
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    color: #005f73;
    margin-bottom: 25px;
    padding-bottom: 10px;
    border-bottom: 2px solid #d0e2e9;
  }
}

.profileContent {
  display:flex;
  flex-direction: row;

.tutorial-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
}

.tutorial-heading {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
    font-weight: bold;
}

.tutorial-description {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
    margin-bottom: 15px;
}

.image-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.tutorial-image {
  max-width: 100%; 
  height: auto; 
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
}

  @media screen and (max-width: 768px) {
    .tutorial-container {
        margin: 10px;
        padding: 15px;
    }

    .tutorial-heading {
        font-size: 20px;
    }

    .tutorial-description {
        font-size: 14px;
    }
  }
}
