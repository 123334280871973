.geo-reports-component-wrapper {
    
    display: flex;
    flex-direction: column; 
    flex-grow: 1; 
    overflow: hidden; 
    background-color: rgb(247, 248, 250, 0.8);
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    position: relative; 

    &-main-content {
        
        font-family: "IBM Plex Sans", sans-serif;
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        position: relative;
        overflow-y: auto;
        height: 100vh;
        max-width: 100vw;
        overflow-x: hidden;
    }
}

.formContainer {
    @extend .customScroll;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08), 0 2px 2px rgba(0, 0, 0, 0.02);
    overflow-y: auto;
    box-sizing: border-box;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    min-width: min-content;
    max-width: 260px;
    max-height: 100vh;

  }
