

.IFCviewer-container{//style={{ width: '100%', height: '100vh', position: 'relative' }}
    /*width: 100%;*/
    /*height: 100vh;*/
    position: relative;

    &-loading-screen-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        z-index: 1000;
    }

    &-3D-viewer {
        /*width: 100%;*/
        /*height: 100%;*/
    }
}


.IFCviewer-component-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100vh;
  /*flex-grow: 1; */
  /*overflow: hidden; */
  background-color: rgb(247, 248, 250, 0.8);
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  position: relative; 
}