$primary-color: #3498db;     // Soft blue
$secondary-color: #e74c3c;   // Dark red
$background-color: #f9f9f9;  // Light gray background
$border-color: #ccc;         // Light gray border
$even-row-color: #f5f5f5;    // Slightly lighter background for even rows
$odd-row-color: #ffffff;     // White background for odd rows

.usersTable {
  overflow-y: auto;
  max-width: 100vw;
  height: 100vh;

  .usersPageHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px;
    background-color: #f4f7f6;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid #dce2e6;
  }

  input {
    max-width: 150px;
  }

}
.page-container {
  position: relative;
}

.page-button {
  cursor: pointer;
}

.page-content {
  position: absolute;
  top: 100%;
  z-index: 9999; // Increased to ensure it's on top of other elements
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px; // Slightly increased padding for better spacing
  width: 300px; // Increased width for more space
  min-height: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); // Enhanced shadow for depth
  text-align: left;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      margin: 8px 0; // Consistent margin for items
      line-height: 1.5; // Improved line height for readability

      input[type="checkbox"] {
        margin-right: 15px; // Increased space between checkbox and text
        cursor: pointer; // Cursor change to indicate interactivity

        &:disabled {
          opacity: 0.6; // Style for disabled state for better UX
        }
      }

      &:hover {
        background-color: #f5f5f5; // Hover effect for list items
      }
    }
  }
}
.insertUser {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    height: 100%;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;

    .text-class {
      color: #333;
      font-size: 14px;
      line-height: 1.5;
    }

    .subheader-class {
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        color: #555;
        padding-bottom: 10px; // Increased bottom margin to separate from form content
        border-bottom: 1px solid #2c3e50; // Elegant bottom border
        margin-bottom: 10px;
    }
    .logo-class {
      display: block; // Ensures the logo is on its own line
      margin: 0 auto; // Centers the logo horizontally
      max-width: 100px; // Adjust this to fit your logo size
      max-height: 100px; // Adjust for logo height, maintaining aspect ratio
      margin-bottom: 20px; // Space below the logo
  }

  .tutorial-class {
      color: #6c757d;
      font-size: 14px;
      border-left: 4px solid #007bff;
      padding-left: 10px;
      margin-bottom: 15px;
      background-color: #f8f9fa;
  }

    
}

// Responsive Design
@media (max-width: 600px) {
    .iwantnewstyle {
        form {
            width: 90%;

            .form-row {
                flex-direction: column;
                align-items: flex-start;

                label, input {
                    flex: none;
                    margin: 5px 0;
                }
            }
        }
    }
}

/* 

.page-container:hover .dropdown-content {
  display: block;
} */




