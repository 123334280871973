
.OverviewWrapper {
    display: flex;
    flex-direction: row;
    .ItemList {
        width: 20%;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        overflow-y: auto; 
        max-height: 100%;
    }
    .title {
        font-size: 1.2em;
        font-weight: bold;
        color: #333; 
        padding: 10px 15px; 
        border-bottom: 1px solid #eee;
        word-wrap: break-word;
        overflow-wrap: break-word;
    
        .nodeCount {
            font-size: 0.8em;
            color: #666;
            font-style: italic;
            margin-left: 10px;
            vertical-align: middle;
            text-align: end;
        }
    }
    
    

    .listComponent {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                font-size: 0.95em;
                color: #555;
                padding: 8px 15px;
                border-bottom: 1px solid #f7f7f7;
                word-wrap: break-word;
                overflow-wrap: break-word;
                white-space: normal;
                hyphens: auto;
                
                &:hover {
                    background-color: #f0f0f0;
                    cursor: pointer;
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
    .changesList {
        @extend .ItemList;
    }
    .graphSection {
        display: flex;
        flex-direction: column;
        height: 100%;
        flex-grow: 1;

        .pieChart {
            color: #555;
            background-color: #f0f0f0;
            border: 1px solid #e0e0e0;
            height: 50%;
            width: auto;
            padding: 15px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            display: flex;
            justify-content: space-between;
            overflow: hidden;
            transition: background-color 0.3s;
            font-family: Arial, sans-serif;
            font-size: 16px;

            .progressBar {
                display: flex;
                flex-direction: column;
                width: 5%;
                height: 100%;
                justify-content: flex-end;
                background-color: #f0f0f0; /* Light gray background */
                border: 1px solid #ccc; /* Light gray border */
                border-radius: 5px; /* Rounded corners */
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
                position: relative;
            
                .progress {
                    background: linear-gradient(to top, darkgreen, #69c16d); /* Gradient green */
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    width: 100%;
                    color: white;
                    font-weight: bold;
                    text-align: center;
                    font-family: 'Arial', sans-serif; /* Sleek, readable font */
                    box-shadow: inset 0 -1px 1px rgba(0, 0, 0, 0.2); /* Inner shadow for depth */
                }
            }
            
        }
        
        .barChart {
            color: #444;
            background-color: #f7f7f7;
            border: 1px solid #e0e0e0;
            height: 50%;
            width: auto;
            padding: 15px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            transition: background-color 0.3s;
            font-family: Georgia, serif;
            font-size: 18px;
        }
        .statusBar {
            background-color: green;
            height: 5%;
        }
        .title {
            color: #555;
            background-color: #f0f0f0;
            border: 1px solid #e0e0e0;
            width: auto;
            padding: 15px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            overflow: hidden;
            transition: background-color 0.3s;
            font-family: Arial, sans-serif;
            font-size: 24px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 1px;
            text-align: center;
          }
    }
}

.history-log-item {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 10px;
    margin: 10px 0 0 0;
    border-left: 3px solid #007bff; // a blue accent line on the left
    background-color: #f8f9fa; // a light grey background
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
    .log-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
  
      .change-owner {
        font-weight: bold;
        color: #333;
        background-color: rgb(219, 219, 219);
        text-align: center;
        align-items: center;
        align-self: center;
        border-radius: 0.5rem;
        padding: 0.2rem;
        font-size: small;
      }
  
      .date {
        text-align: right;
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        font-size: small;
        gap: 1rem;
        .date-date {
            color: #666;
            display: block;
            margin-bottom: 5px;
        }
        .date-time {
            color: #999;
            display: block
        }
      }
    }
  
    .change-description {
        color: #333;
        font-size: 0.95em;
        margin-bottom: 8px;
        line-height: 1.4;
        word-wrap: break-word;
        overflow-wrap: break-word;
        
        font-size: small;
        h3 {
            color:blue;
            margin: 0;
        }
        h4 {
            color: red;
            
        }
        strong {
            color:#5c5c5c;
            margin-left: 10px;
        }
        li {
            width: 100%;
        }
        ul {
            margin: 0;
        }
        blockquote {
            background: #f9f9f9;
            border-left: 10px solid #ccc;
            margin: 1.5em 10px;
            padding: 1em 10px .1em;
            quotes: "\201C" "\201D" "\2018" "\2019";
        }
    }
  
    .status-change {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.9em;
  
      .new-status {
        font-weight: bold;
        color: #28a745; // a green color indicating 'new' or 'positive' status
      }
  
      .status-change-indicator {
        color: #dc3545; // a red color indicating a 'change' or 'attention needed'
      }
    }
}