.globals--module-navbar {

    display: flex;
    position: relative;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    max-height: 100%;
    min-height: 25px;
    padding: 5px 30px;

    //background-color: $primary-color-subPageHeader;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
      padding: 0px 15px;
    }

    &-title {

      color: white;
      text-wrap: nowrap;
      font-weight: bold;
      font-size: clamp(0.6rem, 2vw, 1.2rem);

      display:flex;
      flex-direction: row;
      align-items: center;


      &-arrow {
        margin-left: 20px;
        transition: transform 0.3s ease;

        @media (max-width: 768px) {
          margin-left: 5px;
        }
      }
      &:hover .globals--module-navbar-title-arrow {
        transform: rotate(90deg);
      }


      &:hover {
        .globals--module-navbar-dropdown-tree-menu {
              opacity: 1;
              transform: scaleY(1);
        }
      }
    }

    &-subTitle {
      @extend .globals--module-navbar-title;
      text-wrap: nowrap;
      font-size: clamp(0.1rem, 2vw, 0.8rem);
      font-weight: normal;
    }

    &-dropdown-tree-menu {

      position: absolute;
      top: 100%;
      left: 0;
      background-color: rgba(0,10,20,0.8);

      z-index: 100;
      width: 300px;

      @include quarter-width-phone;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

      opacity: 0;
      transform: scaleY(0);

      transform-origin: top;
      transition: all 0.3s ease-in-out;
      overflow-y: auto;

      height: 90vh;
    }
  }