table {
    border-collapse: collapse;
    background-color: white;
    text-align: left;
    width: 100%;
    max-width: 100vw;
    thead {
        tr {
            background-color: #2c3e50;
            th {
                font-size: clamp(0.4rem, 2vh, 0.6rem);
                color: white;
                font-weight: 700;
                text-transform: uppercase;
                padding: 10px 5px;

                &:first-child {
                    border-top-left-radius: 8px;
                }

                &:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }
    }

    tbody {
        tr {
            &:nth-child(even), &:nth-child(odd) {
                background-color: #f4f7f6;
            }

            &:hover {
                background-color: #e8eeed; 
            }
            
            font-size: clamp(0.4rem, 2vh, 0.8rem);
            td {
                padding: 2px 20px; 
                border-bottom: 1px solid #eee;
                color: #2c3e50;
            }
        }
    }
}
