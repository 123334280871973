

@mixin full-width-phone {
    @media (max-width: 768px) {
        width: 100% !important;
    }
}

@mixin quarter-width-phone {
    @media (max-width: 768px) {
        width: 75% !important;
    }
}

@mixin hide-on-phone {
    @media (max-width: 768px) {
        display: none !important;
    }
}