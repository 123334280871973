$primary-color: #00a76d; // Dark shade for primary elements
$cool-blue: #2c3e50;
$secondary-color: #a4c3b2; // Soft green for accents
$border-radius: 8px;

.dropdown {
  position: relative;
  &-button {
    background: transparent;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    &:hover {
      color: $primary-color;
    }
  }

/*   &:hover .dropdown-content {
    display: flex !important;
    flex-direction: column;
  } */


  &-content {

    left: 0;
    top: 100%;
    position: absolute;
    z-index: 100;
    background-color: white;
    border-radius: $border-radius;
    width: 300px;
    max-width: 300px;
    height: 100vh;
    display: flex !important;
    flex-direction: column;

  }
}


  .subPageContent {
    padding: 5px;
  }



.full-screen-div-lock {
  z-index: 10;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top:0;
  left:0;
  background-color: rgba(0, 0, 0, 0.3);
}
.resizable-popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 40%;
  height: 90vh;
  max-width: 90%;
  min-width: 40%;
  max-height: 90vh;
  min-height: 40vh;
  resize: both;
  overflow: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;


  @media (max-width: 768px) {
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
  }
  .content {
    flex-grow: 1;
    min-height: 100%;
    height: 100px;
  }
  .content2 {
    max-height: 90vh;
    min-height: 40vh;
  }

  .controls {
    position: absolute;
    display: flex;
    min-width: 20%;
    justify-content: space-around;
    padding: 10px;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1001;
    border-radius: 4px;
    padding: 10px;
    box-sizing: border-box;
  }
}

.global-list {
  color: #333;
  width: 100%;
  height: 100%;
  margin: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;

  .upper-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
    background-color: #fafffd;
  }
  .fix-tags-container {
    margin-top: 20px;
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .fix-tags-title {
        margin-bottom: 10px;
        color: #333;
        font-size: 20px;
        font-weight: 600;
    }
    .fix-tags-container {
      position: relative;
  }

  .close-fix-tags-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: 1px solid #d1d1d1;
    background-color: #f5f5f5;
    border-radius: 15px;
    cursor: pointer;
    font-size: 14px;
    color: #333;
    text-align: center;
    line-height: 1;
    padding: 0;
    transition: all 0.3s ease;
}

.close-fix-tags-btn:hover {
    background-color: salmon;
    border-color: #c1c1c1;
    color: #222;
}

.close-fix-tags-btn:active {
    background-color: #d1d1d1;
    border-color: #b1b1b1;
    color: #111;
}

}


  .title {
    font-size: 24px;
    color: #333;
    text-align: center;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    width: 100%;

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

    @media (max-width: 600px) {
      font-size: 20px;
    }
  }
}
.tagsLabel {
  margin-right: 10px;
  font-weight: bold;
  color: #333;
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
}

.tagItem {
  background-color: #e8f0fe;
  border-radius: 15px;
  border-color: transparent;
  padding: 4px 6px;
  margin: 0px 2px;
  font-size: clamp(0.6rem, 2vh, 0.8rem) ;
  color: #1a73e8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #1a73e8;
    color: #ffffff;
    cursor: pointer;
  }
}
