
body,html {
    margin: 0;
    background: /* linear-gradient(to bottom right, #00a76d, #007a5d, #00a76d) */ grey;
    //background-image: url('https://images.squarespace-cdn.com/content/v1/5dbac81daa74663c10257737/5e8b0006-69f9-46fc-989b-d0ca8635e9df/Artboard+1+copy+5.png?format=2500w');
    //background-image: url('https://images.squarespace-cdn.com/content/v1/5dbac81daa74663c10257737/1572533792272-TQM6N4KP5NVCX2COEYOB/image-asset.jpeg?format=2500w');
    background-image: url('../../public/predor_karavanke.jpg');
    background-size: cover;   /* Ensures the background covers the entire element */
    background-position: center;  /* Centers the background image */
    background-repeat: no-repeat; 
    font-family: $font-family;

    height: 100vh; /* Use 100vh for height */
    max-height: 100vh;/* 
    overflow: auto; */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;


}
.index-component-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column; /* This is crucial */
    overflow: hidden; /* Prevents overflow from this container */
    box-sizing: border-box;
    max-height: 100vh;
    &-dropdown-menu {
        position: absolute;
        top: 0;
        left: 0;
        width: 300px;
        max-width: 300px;
        height: 100dvh;
        padding-top: 50px;

        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-right: 5px solid rgba(255, 255, 255, 0.7);
        
        z-index: 500;

        background-color: #00a76d;
        box-shadow: 0 2px 4px rgba(0,0,0);

        transform: translateX(-300px); 
        transition: transform 0.3s ease; 

        &-title {
            @extend .index-component-wrapper-navbar-title-text;
            justify-content: center;
            padding: 5px
        }
        
        

        button {
            a {
                text-decoration: none;
                color: inherit; 
                min-width: 100%;
                flex:1;
                padding: 10px;
            }
            display: flex;
            padding: 0;
            width: 100%;
            font-family: $font-family;
            text-align: left;
            background: none;
            cursor: pointer;
            color: white;
            font-size: clamp(14px, 2vw, 16px); /* Responsive font size using clamp */
            transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.2s ease;
            border-radius: 4px;
            border: none;
            
            &:hover {
                background-color: midnightblue;
                box-shadow: 0 4px 8px rgba(0,0,0,0.1);
            }
        }
    }


    &-log-in {
        position: absolute;
        left: 45%;
        top: 45;
    }

    &-navbar {
        @extend .helpers--section-container-orientation-flex-row;
        user-select: none;
        color: white;
        justify-content: space-between;
        padding: 0.2rem 1rem;
        border-bottom: 1px solid white;
        background-color: #00a76d;

        &-utility-menu {
            @extend .helpers--section-container-orientation-flex-row;
            gap: 10px;

        }
        &-title {
            @extend .helpers--section-container-orientation-flex-row;
            cursor: pointer;
            &-logo {
                margin-right: 10px;
                vertical-align: bottom;
                margin-bottom: 0.1em;
                width: clamp(1rem, 2vw, 1.5rem);
                height: auto;
            }
            &-text {
                font-size: clamp(1rem, 2vw, 1.5rem);
                font-weight: bold;
                display: flex;
                align-items: center; 
                cursor: pointer;

                &:hover {
                    color: whitesmoke
                }
            }
        }
    }

    &-main-content {
        flex-grow: 1;
        overflow-y: hidden;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
    }
}
.openMenu {
    transform: translateX(0%);
}

*, *:before, *:after {
    box-sizing: border-box;
}

