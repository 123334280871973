.userAccessControlPanel {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  background-color: transparent;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  position: relative;

    .content {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      box-sizing: border-box;
      height: 100vh;
      overflow-x: hidden;

      .tableContainer {
        background-color: #f4f7f6;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        overflow: auto;
        transition: box-shadow 0.3s ease;
        width: 50vw;

        &:hover {
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
        }
      }
      .node-tree-section {
        padding: 20px;
        background-color: transparent;
        width: 50%;
        overflow: auto;
        box-sizing: border-box;
      }
    }
  }
