.form {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-width: 500px;
    padding: $form-padding;
    border: 1px solid $border-color;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: white;
    max-height: 100vh;
    overflow: auto;

    font-size: clamp(0.6rem, 2vh, 0.8rem);

    .form-header, .header {
        font-weight: 600;
        text-align: center;
        
        color: #2c3e50; 
        padding: 10px 0;
        margin-bottom: 10px;
        border-bottom: 1px solid #2c3e50;
        text-decoration: none;
        letter-spacing: 0.5px;

        
        font-weight: bold;
        margin-bottom: $section-padding-bottom; 
        margin-top: $section-padding-top; 
        color: $primary-color;
        display: grid;
        grid-template-columns: 1fr 5fr 1fr;
    }

    .form-row {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        label {
            width: 30%;
            text-align: right;
            margin-right: 10px;
        }

        input {
            flex-grow: 1;
            padding: 8px 12px;
            border-radius: 5px;
            border: 1px solid #ccc;
            &:focus {
                outline: none;
            }
        }
    }

    .section {
        display: grid;
        grid-template-columns: 3fr 4fr;
        padding: $section-padding-bottom 0;
        border-top: 1px solid $border-color;

        .sectionName {
            font-weight: bold;
            color: $primary-color;

            &.additionalSectionColor {
                color: $additionalSectionColor;
                background-color: rgba(0, 0, 0, 0.1);
            }
        }

        .dataSection {
            text-align: right;
        }
    }
}
