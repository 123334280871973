.globals--regular-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    font-size: 14px;
    padding: 6px 12px;
    background-color: #ffffff;
    color: #4a4a4a;

    transition: filter 0.25s ease;

    &:hover {
        filter: brightness(98%);
        transition: background-color 0.25s ease;
    }
    @media (max-width: 768px) {
      padding: 2px 4px;
      font-size: clamp(0.6rem, 2vw, 1.2rem);
    }


    i {
      display: flex;
      justify-content: center;
      margin-right: 5px;
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  } //buttons

  .globals--green-button {
    @extend .globals--regular-button;

    background-color: #a4c3b2;
    color: white;

    &:hover {
      background-color: darken(#a4c3b2, 10%);
    }
  }

  .globals--red-button {
    @extend .globals--regular-button;

    background-color: #c44d58; /* Grey shade */
    color: white;

    &:hover {
      background-color: darken(#d1d3d4, 10%);
    }
  }

  .globals--transparent-button {
    @extend .globals--green-button;
    background-color: transparent;
    border: 1px solid transparent;
    &:hover {
      background-color: midnightblue;
      color: #bcf124;
      border: 1px solid #bcf124;
    }
  }

  .globals--close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: #ff3b30;
    color: white;
    border: 1px solid #ccc;
    padding: 0;
    border-radius: 50%;
    cursor: pointer;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    width: 30px;
    height: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, color 0.3s;

    &:hover {
        background-color: #cc2a1f;
        color: #1a1a1a;
    }

    &:before {
        content: "×";
        display: block;
    }
}

.globals--chat-button{
  @extend .globals--close-button;
  position: sticky;
  color: blue;
  background-color: whitesmoke;
  &:before {
    content: none;
    display: block;
  }
  &:hover {
        background-color: blue;
        color: whitesmoke;
    }
}

.copyToClipButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #4a90e2;
  transition: color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    color: lighten(#4a90e2, 10%);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.5);
  }

  &:active {
    color: darken(#4a90e2, 10%);
    transform: translateY(1px);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }
}