
//------------------------------------ Main -------------------------------

$DBborderColor: /* rgb(128, 127, 127) */transparent;
$DBborderStyle: solid;
$DBborderSize: 1px;
//------------------------------------ Entered content ----------------------------
$DBenteredContentColor: /* brown */rgb(55, 55, 55);
$DBenteredContentFontFamily:'Times New Roman', Times, serif;
$DBenteredDeadlineColor: red;
//------------------------------------ Section ----------------------------
$DBsectonTopBorderColor:black;
$DBsectionTopBorderSize: 1px;
$DBsectionTopBorderStyle: solid;

$DBsectionFontWeight:bold;
//------------------------------------ Buttons ----------------------------
//regular
$buttonsColor: whitesmoke;

//------------------------------------ TextFields ----------------------------
$DBtextInputBackgroundColor: rgb(243, 243, 243);
$DBtextInputBorderColor: rgb(112, 107, 107);
$DBtextInputBorderSize: 2px;
$DBtextInputBorderStyle: double;
$DBtextInputBorderRadius: 5px;
$DBtextInputFocusColor: white;
$DBtextInputFontSize: 13px;
$DBtextInputFontSize2: 12px;
$DBsectionTitleFontSize:12px;
//----------------------------------------------------------------------------


.descrBox-wrapper {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    padding: 12px;
    width: 250px;
    margin: 5px;
    border-radius: 8px;
    font-size: clamp(0.6rem, 2vh, 0.8rem) ;

    .DBhead {
        font-weight: 600; 
        color: #333; 
        text-align: center;
        font-style: normal;
        justify-content: space-between;
        padding: 10px 0; 
        border-bottom: $DBborderSize $DBborderStyle $DBborderColor;

        .title {
            font-size: 20px; 
            text-decoration: none; 
            font-weight: 600; 
            color: #555; 
            max-width: 100%;
            word-wrap: break-word;
            overflow-wrap: break-word;
        }
    };
    
    .status {
        padding: 4px;
        border-top: $DBsectionTopBorderSize $DBsectionTopBorderStyle $DBsectonTopBorderColor;
        display:grid;
        grid-template-columns:1fr 2fr;
        align-items: center;
        color: black;
        

        .statusBar {
            border: 1px solid $DBtextInputBorderColor;
            border-radius: 10px;
            text-align: center;
            font-size: 12px;
            box-sizing: border-box;
            cursor:pointer;
            background-color: white;
            
            &.notForm {
                cursor:default; 
                grid-column-start: 1;
                grid-column-end: 3;

            }
            &.isForm {
                width:160px;
            }
        }
        .statusNameMask {
            width: 206px;
        }
    }
    
    .sectionTitle {
        padding: 8px 8px; 
        border-top: $DBsectionTopBorderSize $DBsectionTopBorderStyle $DBsectonTopBorderColor;
        height: auto; 
        text-align: center;
        display: flex; 
        justify-content: center;
        align-items: center;
    
        .alignRight {
            padding: 4px 0;
            color: #777;
            justify-content: flex-end;
        }
        
        &.flex {
            display: flex;
            justify-content: space-between;
        }
        
        &.noBorder {
            border-top: none !important;
        }
    }

    .footer {
        display: grid;
        grid-template-columns: 1fr 2fr;
        align-items: center;
        text-align: center;
        font-weight: bold;
        border-top: $DBborderSize $DBborderStyle $DBborderColor;
        padding: 7px 0 2px;
    }

    .textInput {
        padding: 5px;
        color: $DBenteredContentColor;
        font-size: $DBtextInputFontSize;
        width:100%;
        box-sizing: border-box;
        resize:vertical;
        word-break: break-all !important;
        

        &.sectionInput {
            width:50px;
            text-align: center;
        }
    }

    .fileName {
        font-family:$DBenteredContentFontFamily;
        padding: 0  5px 5px;
        font-weight: bold;
        color: $DBenteredContentColor;
        font-size: 12px;
        word-break: break-all;
        user-select:text;
    }

    .trashCan {
        height:35px;
        padding:0px 7px 0px 8px;
        font-size: 25px;
        border: 1px solid $DBborderColor;
        border-radius: 30px;
        color: black;
        cursor: pointer;
        float: right;

        &:active {
            border-color: darkred;
            color:darkred;
        }
    }
      
}
