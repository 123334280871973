$global-small-font-clamp: clamp(0.6rem, 2vh, 0.8rem);

.globals--search-bar {
    background-color: #ffffff;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    padding: 0px 0px 0px 12px;

    &-info-badge {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: $global-small-font-clamp;
        color: #5a5a5a;
        border-right: 1px solid #d3d3d3;
        padding: 0px 5px;
        text-wrap: nowrap;
    }
  }