.text-area-overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 80%;
    max-height: 90vh;
    max-width: 30vw;
    min-height: 300px;
    min-width: 300px;
    overflow: auto;
    background-color: #00A24D;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    padding: 30px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    &.data-display {
      resize: both;
      max-width: 90%;
      min-width: 50%;
    }

    .button-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &.data-display-buttons {
        flex-direction: row;
        justify-content: space-around;
      }
    }
    .preview-buttons {
      width:100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
    
    .excel-preview {
        width: 100%;
        max-height: 80%;
        overflow: auto;
        margin-bottom: 20px;
        padding-top: 20px;
        background-color: #f2f2f2;
        color: black;
        border-radius: 5px;
        box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
    }
}